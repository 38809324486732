@import '../../../../styles/colors';

.groups {
    .groupContainer {
        border: 1px solid $color-gray;

        &:first-of-type {
            border-top-left-radius: 0.3rem;
            border-top-right-radius: 0.3rem;
        }

        &:last-of-type {
            border-bottom-left-radius: 0.3rem;
            border-bottom-right-radius: 0.3rem;
        }
    
        .groupInfo {
            padding: 1rem;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-weight: bold;

            svg {
                font-size: 1.5rem;
                color: $color-gray-dark;
            }

            &:hover {
                background-color: $color-orange-lightest;
            }
        }

        .achievementsContainer {
            display: flex;
            flex-direction: row;
            padding: 1rem 0;
            background-color: $color-bg-header;

        .achievement {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 7rem;
            margin: 0 0.5rem;

            .achievementIcon {
                max-width: 6rem;
                max-height: 3rem;
            }

            .achievementIconBadge {
                position: absolute;
                background-color: $color-error;
                border: 1px solid $color-bg;
                color: $color-bg;
                line-height: 0.7rem;
                border-radius: 1rem;
                font-size: 1rem;
                font-weight: bold;
                padding: 0.3rem 0.4rem;
                margin-left: 3rem;
                margin-top: -0.5rem;
            }

            .achievementName {
                margin-top: 0.2rem;
                font-size: 1rem;
                line-height: 1rem;
                text-align: center;
            }
        }
        }


        .statsContainer {
            position: relative;
            min-height: 10rem;
            border-top: 1px solid $color-gray;

            .notAvailable {
                text-transform: uppercase;
                color: $color-gray-dark;
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 5rem;
            }

            h3 {
                text-align: center;
            }

            .stats {
                .statsItem {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    font-weight: bold;
                    padding: 1rem 1rem;
                    width: 100%;
                    box-sizing: border-box;
                    background-color: $color-bg;
        
                    &:nth-child(2n) {
                        background-color: $color-gray-light;
                    }
        
                    .statsItemName {
                        flex-grow: 10;
                        font-weight: normal;
                    }
        
                    .statsItemVal {
                        width: 7rem;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
        
                        .icon {
                            color: $color-orange-dark;
                            margin-right: 0.5rem;
                        }

                        .achievementIcon {
                            max-width: 4rem;
                            max-height: 2rem;
                            margin-right: 0.5rem;
                        }
                    }
                }
            }

            .usersListPager {
                margin: 0 1rem;
            }
            .usersList {
                width: 100%;
                background-color: $color-bg;

                th {
                    background-color: $color-gray;
                }
                
                th, td {
                    padding: 0.5rem;
                }

                tr:nth-child(2n) {
                    td {
                        background-color: $color-gray-light;
                    }
                }

                th:first-child,
                td:first-child {
                    min-width: 2rem;
                    text-align: center;
                }

                th:last-child,
                td:last-child {
                    width: 7rem;
                }

                th:nth-child(2),
                td:nth-child(2) {
                    padding-left: 1rem;
                }
            }
        }

        &.active {
            background-color: $color-gray-light;
        }
    }
}

.timesIcon {
    font-size: 1.5em;
}