@import '../../../../styles/colors';


.container {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 4rem;
    box-sizing: border-box;

    .header {
        font-size: 1.5rem;
        line-height: 2.5rem;
        font-weight: bold;
        margin: 1rem 0;
    }

    .points {
        font-size: 2rem;
        line-height: 2.5rem;
        font-weight: 800;
    }

    .achievement {
        margin: 2rem 0 1rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    :global(.button) {
        margin-top: 4rem;
        margin-bottom: 3rem;
    }

    .icon {
        max-width: 50%;
        max-height: 10rem;
    }
}
