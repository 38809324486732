@import "../../../../styles/colors";

.container {
  width: 768px;
  max-width: 100%;
  min-height: 7rem;

  .rootSkill {
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;

    &:first-child {
      margin-top: 0;
    }
  }

  .subSkill {
    padding: 0.5rem 1rem;
    padding-left: 0;
    margin-left: 2rem;
    border-bottom: 2px solid $color-gray;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .subSkillAndIconContainer {
      font-weight: normal;
    }

    .infoIcon {
      font-size: medium;
      margin-left: 2rem;
      color: black;
      cursor: pointer;
    }

    > div {
      font-weight: bold;
      margin-right: 0;
    }
  }
}
