@import '../../../styles/colors';

.hidden {
    display: none;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .hintText {
        padding-top: 10px;
        color: $color-gray-text;
        align-items: center;
    }

    .list {
        width: 100%;
        max-width: 768px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .showHideCompletedBtnContainer {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .showHideCompletedBorder {
                border: 1px solid $color-gray;
                border-top: none;
                border-bottom-left-radius: 0.3rem;
                border-bottom-right-radius: 0.3rem;
                width: 100%;
                box-sizing: border-box;
                height: 2rem;
                background-color: $color-bg-header;
                position: absolute;
            }

            .showHideCompletedGradient {
                background-image: linear-gradient($color-bg, #ffffff11);
                width: 100%;
                height: 2rem;
                position: absolute;
            }

            button {
                margin-top: 2rem;
            }
        }

        .emotionSystem {
            border: 1px solid $color-gray;
            border-radius: 0.3rem;
            padding: 0 1rem 1rem 1rem;
            width: 100%;
            max-width: 768px;
            box-sizing: border-box;
            background-color: $color-bg-header;

            h3 {
                text-align: left;
            }

        }

        .nextItemsTitle {
            width: 100%;
            font-size: 1.2rem;
            font-weight: bold;
            margin-top: 2rem;
        }

        .item {
            width: 100%;
            margin: 3vh 0;
            padding: 1rem;
            box-sizing: border-box;
            border: 1px solid $color-gray;
            background-color: $color-bg-header;
            border-radius: 0.3rem;

            .itemSubcontainer {
                display: flex;
                flex-direction: row;

                .icon {
                    width: 5rem;
                    flex-shrink: 1;

                    img {
                        width: 3rem;
                        margin-top: 1rem;
                    }
                }

                .icon img.pencil {
                    display: none;
                }
                .icon img.brain {
                    display: none;
                }
                .icon img.hands {
                    display: inline-block;
                }

                .itemInfo {
                    flex-shrink: 10;
                    flex-grow: 10;

                    >.title {
                        font-size: 1.2rem;
                        font-weight: bold;
                    }

                    >.description {
                        margin-top: 1rem;
                    }

                    .alert {
                        color: #FEAB02;
                    }

                    >.featuring {
                        border-top: 1px solid $color-gray-dark;
                        margin-top: 2rem;
                        display: none;
                        margin-right: 5rem;

                        >.featuringTitle {
                            margin-top: -1rem;
                            margin-left: 50%;
                            transform: translateX(-50%);
                            padding: 0 1rem;
                            background-color: $color-bg-header;
                            font-size: 0.8rem;
                            font-weight: bold;
                            float: left;
                            text-transform: uppercase;
                        }

                        .characters {
                            width: 100%;
                            display: flex;
                            flex-wrap: wrap;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            position: relative;
                            min-height: 4rem;

                            .character {
                                margin: 1rem 1rem 0 1rem;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                font-size: 0.8rem;

                                img {
                                    display: block;
                                    width: 5rem;
                                    height: 5rem;
                                    box-sizing: border-box;
                                    clip-path: circle(50% at 50% 50%);
                                    border: 3px solid $color-btn-green;
                                    border-radius: 50%;
                                }
                            }
                        }

                    }
                }
            }

            .buttons {
                padding-top: 2rem;
                display: flex;
                flex-direction: row;
                justify-content: center;
            }


            &.new {
                .icon img.hands {
                    display: inline-block;
                }

                &:not(.current) {
                    .buttons {
                        display: none;
                    }
                }

                &.current {
                    .icon img.customIcon {
                        display: inline-block;
                        max-width: 15rem;
                    }
                    .icon img.brain {
                        display: inline-block;
                    }
                    .icon img.hands {
                        display: none;
                    }
                    .featuring {
                        display: block;
                    }
                }
            }

            &.completed {

                .icon img.pencil {
                    display: inline-block;
                }
                .icon img.hands {
                    display: none;
                }
            }


        }
    }
}
