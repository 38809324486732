@import '../../styles/colors';

.search {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.characters {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .characterImage {
        margin-bottom: 0.5rem;
        font-size: 0.8rem;
        display: flex;

        img {
            width: 6rem;
            height: 6rem;
            border-radius: 50%;
            border: 2px solid $color-btn-green;
        }
    }
}

.list {
    @media screen and (max-width:565px) {
        border: 0 !important;
        tr:nth-child(even) td {
            background-color: transparent !important;
        }
    }

    td {

        &:first-child {
            position: relative;

            .markContainer {
                height: 100%;
                display: flex;
                flex-direction: row;

                .mark {
                    position: absolute;
                    top: 10%;
                    left: 0;
                    height: 80%;
                    width: 1px;
                    float: left;
                    border-left: 5px solid $color-green;
                    margin-right: 2rem;

                    &.disabled {

                        border-color: $color-orange;
                    }
                    @media screen and (max-width:565px) {
                       display: none;
                    }
                }

                .title {
                    margin-left: 1rem;
                    flex-grow: 20;
                    @media screen and (max-width:565px) {
                        margin-left: 0;
                    }
                }
            }

        }

        .scene {
            color: $color-gray-text;

            span {
                font-weight: bold;
            }
        }

        .character {
            color: $color-gray-text;

            span {
                font-weight: bold;
            }
        }

        .emotions {
            color: $color-orange-dark;

            span {
                font-weight: bold;
            }
        }

        .intensity {
            span {
              font-weight: bold;
            }
        }

        .time {
            text-align: center;
        }


        .rating {
            text-align: left;

            span {
              font-weight: bold;
            }
        }

        .episode {
            color: $color-gray-dark;
            text-align: left;

            span {
                font-weight: bold;
            }
        }

        .narrative {
            color: $color-blue;
            text-align: left;

            span {
                font-weight: bold;
            }
        }

        .tags {
            span {
              font-weight: thin;
            }
        }

        .triggers {
            span {
              font-weight: bold;
            }
        }

    }
}

.info {
  border: 1px solid $color-orange;
  border-radius: 0.3rem;
  box-shadow: -1px 1px 1px $color-orange-dark;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin-bottom: 2rem;
  margin-top: 1rem;

  @media screen and (max-width:565px) {
      font-size: 0.8rem;
  }

  .message {
    padding: 1rem 1rem;
  }

  .closeButton {
    align-items: center;
    text-align: center;
    font-weight: bold;
  }
}

.betaMessage {
    font-size: 1rem;
    justify-content: center;
    text-align: center;
    padding: 2rem 0rem;
    width: 100%;
    max-width: 100%;

    @media screen and (max-width:565px) {
        font-size: 0.8rem;
        margin-top: 50px;
    }

}

.centerBlock {
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    .noMoreResults {
      color: $color-gray-dark;
    }

    .showMore {

    }

    .hint {
      margin-top: 1rem;
      @media screen and (max-width:565px) {
          font-size: 0.8rem;
      }
    }
}
.commentField {
    width: 100%;
    height: 5rem;
}
.filters {
    display: flex;
    flex-direction: row;
    margin: 1rem 0;
    justify-content: center;


    .filter {

        span {
            padding-left: 0.2rem;
        }

        &.filterService {
            width: 20rem;
        }

        &.filterAge {
            padding-left: 0.2rem;
            width: 15rem;
        }
    }
  }
.mobileResult{
    display: none;
    @media screen and (max-width:565px) {
        display: block;
    }
    button{
        padding: 0;
    }
    svg{
        margin-left: 0;
    }
}
.mobileTable{
    display: revert;
    @media screen and (max-width:565px) {
        display: none;
        border: 0;
    }
}
.link {
    color: $color-txt;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    text-decoration: underline;

    @media screen and (max-width:565px) {
        font-size: 0.8rem;
    }
}
