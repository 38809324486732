@import '../../../../styles/colors';

@keyframes schema-fadeout {
    0% {
        opacity: 100%;
    }
    100% {
        opacity: 0%;
    }
}

.finalEmotionModelState {
    width: 100%;
    margin-top: 2vh;
    cursor: pointer;
    border-radius: 0.3rem;
    // border: 4px solid $color-orange;
    // background-color: $color-orange-lightest;

    .finalEmotionModelStateHeader {
        color: $color-bg-header;
        // background-color: $color-orange;
        // border: 4px solid $color-orange;
        border-radius: 0.3rem;
        font-size: 0.8rem;
        line-height: 1rem;
        font-weight: bold;
        float: left;
        margin-top: -1.4rem;
        margin-left: -0.2rem;
    }

    .schema {
        position: relative;
        margin-top: 1rem;

        .imgContainer {
            // background-color: $color-orange-lightest;
            height: 30vh;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            &:nth-child(2) {
                position: absolute;
                top: 0;
                left: 0;
                animation-duration: 5s;
                animation-name: schema-fadeout;
                opacity: 0%;
            }

            img {
                height: 100%;
                max-width: 100%;
            }
        }
    }
}

.emotionModel {
    min-width: 50vw;
    max-width: 100vw;
}
