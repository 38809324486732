@import '../../../styles/colors';

.link {
    color: $color-txt;
    font-weight: bold;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-size: 1rem;

    &.disabled {
        opacity: 0.2;
    }

    &:hover {
        text-decoration: underline;
    }

    svg {
        margin: 0 0.5rem;
    }

    &:global(.red) {
        color: $color-btn-red;
    }

    &:global(.orange) {
        color: $color-orange-dark;
    }

    &:global(.gray) {
        color: $color-gray-dark;
    }

    &:global(.blue) {
        color: $color-blue;
    }
}