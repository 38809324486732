@import '../../../../styles/colors';



.topBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.info {
  border: 1px solid $color-orange;
  border-radius: 0.3rem;
  box-shadow: -1px 1px 1px $color-orange-dark;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin-bottom: 2rem;

  .message {
    padding: 1rem 1rem;
  }

  .closeButton {
    align-items: center;
    text-align: center;
  }
}

.stats {
    border: 1px solid $color-gray;
    border-radius: 0.3rem;
    box-shadow: -1px 1px 1px $color-gray;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin-bottom: 2rem;

    h3 {
        text-align: center;
    }

    .achievementsContainer {
        display: flex;
        flex-direction: row;
        padding: 1rem 0;
        background-color: $color-bg-header;

        .achievement {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 7rem;
            margin: 0 0.5rem;

            .achievementIcon {
                max-width: 6rem;
                max-height: 3rem;
            }

            .achievementIconBadge {
                position: absolute;
                background-color: $color-error;
                border: 1px solid $color-bg;
                color: $color-bg;
                line-height: 0.7rem;
                border-radius: 1rem;
                font-size: 1rem;
                font-weight: bold;
                padding: 0.3rem 0.4rem;
                margin-left: 3rem;
                margin-top: -0.5rem;
            }

            .achievementName {
                margin-top: 0.2rem;
                font-size: 1rem;
                line-height: 1rem;
                text-align: center;
            }
        }
    }

    .statsContainer {

        .statsItem {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            font-weight: bold;
            padding: 1rem 1rem;
            width: 100%;
            box-sizing: border-box;
            background-color: $color-gray-light;

            &:nth-child(2n) {
                background-color: $color-bg;
            }

            .statsItemName {
                flex-grow: 15;
                display: flex;
                flex-direction: row;
                white-space: nowrap;
                font-weight: normal;
                justify-content: flex-start;

                .statsItemInfo {
                  margin: 0 0.5rem;
                  cursor: pointer;
                }
            }

            .statsItemVal {
                width: 7rem;

                .icon {
                    color: $color-orange-dark;
                    margin-right: 0.5rem;
                }

                .pipsIcon {
                  padding: .5rem 0 0 0;
                  max-height: 1.5rem;
                  margin-right: .5rem;
                }

                .achievementIcon {
                    max-width: 4rem;
                    max-height: 2rem;
                    margin-right: 0.5rem;
                }
            }
        }
    }
}



    .chartContainer {
        padding-top: 1rem;
        border-bottom: 1px solid $color-gray;

        &:last-child {
            border-bottom: none;
        }

        .header {
            display: flex;
            justify-content: space-between;
            margin: 0 1rem;

            .left {
                .chartTitle {
                    font-size: 1.2rem;
                    font-weight: 700;
                }

                .level{

                    span {
                        font-weight: 700;

                        &.practice_needed {
                            color: $color-red;
                        }

                        &.fair {
                            color: $color-orange-dark;
                        }

                        &.good {
                            color: $color-orange;
                        }

                        &.very_good {
                            color: #00a0b1;
                        }

                        &.excellent {
                            color: #008d8b;
                        }
                    }
                }
            }
        }

        .nothing {
            font-size: 0.8rem;
            margin-left: 2rem;
            margin-top: 1rem;
        }
    }

    .curriculumName {
            font-weight: bold;
            border-bottom: 2px solid $color-orange;

            span {
                background-color: $color-orange;
                color: #fff;
                font-weight: 200;
                line-height: 1.5rem;
                padding: 0 0.5rem;
                display: inline-block;
                border-top-left-radius: 8px;
            }
        }

        .unitContainer {
            margin: 1rem 0 1rem 4rem;

            .unitName {
                border-bottom: 2px solid $color-green;

                span {
                    background-color: $color-green;
                    color: #fff;
                    font-weight: 200;
                    line-height: 1.5rem;
                    padding: 0 1rem;
                    display: inline-block;
                    border-top-left-radius: 8px;
                }
            }

            .statItem {
                margin: 1rem 4rem;
                display: flex;
                flex-direction: row;

                .dateTime {
                    border-bottom: 1px solid $color-gray;
                }

                .points {
                    color: $color-gray-text;
                    padding-left: 2rem;
                    border-bottom: 1px solid $color-gray;

                    span {
                        font-weight: bold;
                    }
                }

                .time {
                    color: $color-gray-text;
                    padding-left: 2rem;
                    border-bottom: 1px solid $color-gray;

                    span {
                        font-weight: bold;
                    }
                }

               .score {
                    color: $color-gray-text;
                    padding-left: 2rem;
                    border-bottom: 1px solid $color-gray;

                    span {
                        font-weight: bold;
                    }
                }                
                
            }
          }


.timesIcon {
    font-size: 1.5em;
}


@media screen and (max-width: 1200px) {
    .topBlock {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .stats {
            width: 100%;

            &:first-child {
                margin-bottom: 3rem;
            }
        }
    }
}


@media screen and (max-width: 700px) {
    .stats {
        .statsItem {
            flex-direction: column;
            align-items: flex-start;
        }
    }
}
