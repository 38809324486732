@import '../../../../styles/colors';

.editor {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .formContainer {
        width: 800px;
        padding: 2rem;
    }
}


@media screen and (max-width: 768px) {
    .editor {

        .formContainer {
            box-sizing: border-box;
            width: 100%;
            padding: 1rem;
        }
    }
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: 680px){
        background-color: $color-gray;
    }
    min-height: 100vh;

    .content {
        margin-top: 6rem;
        margin-bottom: 4rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        min-height: 50vh;
        border-radius: 8px;
        @media screen and (min-width: 680px){
            background-color: $color-bg;
            width: 680px;
        }


        img {
            margin-left: auto;
            margin-right: auto;
            padding-top: 10px;
            padding-bottom: 10px;
            display: block;
            max-width: 90%;
        }

        .text {
            padding: 40px;
        }

    }
}
