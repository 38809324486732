@import '../../styles/colors';

.locker {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(darken($color-green, 60%), 0.9);
    z-index: 1000000;

}

.popup {
    position: static;
    background-color: $color-bg;
    border: 1px solid $color-gray;
    box-shadow: 0 0 7px rgba($color-txt, 0.7);
    border-radius: 7px;
    top: 10rem;
    z-index: 1000000;

    @media only screen and (max-width: 565px) {
        left: 1rem;
        right: 1rem;
    }

    .close {
        color: $color-border;
        top: 0.5rem;
        font-size: 1.5rem;
        position: absolute;
        right: 1rem;
        top: 0.5rem;
        cursor: pointer;
    }

    .header {
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        min-height: 3rem;
        background-color: $color-gray;

        .title {
            padding: 0.5rem 3rem 0.5rem 2rem;
            font-weight: bold;

            @media only screen and (max-width: 565px) {
                text-align: center;
                padding-top: 15px;
            }
        }
    }

    .body {
        padding: 2rem;
    }

    .buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 1rem 1rem 1rem;

        &.single {
            justify-content: center;
        }

        .button {
            margin: 0 1rem;
        }

        @media only screen and (max-width: 565px) {
            flex-direction: column;

            .button {
                margin: .5rem 0;
            }
        }
    }

    &:global(.success),
    &:global(.green) {

        .close {
            color: $color-bg;
        }

        .header {
            background-color: $color-btn-green;
        }
    }

    &:global(.orange) {

        .header {
            background-color: $color-orange-light;
        }
    }


    &:global(.error),
    &:global(.red) {

        .close {
            color: $color-bg;
        }

        .header {
            background-color: $color-error;

            .title {
                color: $color-bg;
            }
        }
    }

}
