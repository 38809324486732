@import '../../styles/colors';

.search {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1rem;
    @media screen and (max-width:565px) {
        margin-left: 0rem;
    }

    .searchIcon {
        position: absolute;
        margin: 0.5rem 0 0 0.3rem;
        color: $color-orange;
    }

    .clearSearchFieldBtn {
        color: $color-gray-dark;
        position: absolute;
        margin-top: 0.5rem;
        margin-left: -1.5rem;
    }

    input[type=text].searchField {
        width: 5rem;
        height: 1.25rem;
        padding: 0.3rem 2rem;
        margin-right: 0.5rem;
        font-size: 1rem;
    }


    &.active {
        input[type=text].searchField {
            width: 18rem;
            @media screen and (max-width:768px) {
                width: 12rem;
            }
            @media screen and (max-width:565px) {
                width: 12rem;
                margin-left: -0.5rem;
            }
        }
    }

}
.searchBtn {
    min-height: 2.5rem;
    border-radius: 1.5rem;
    background-color: $color-btn-green;
    min-width: 13rem;
    font-size: 0.8rem;
    font-weight: bold;
    border: none;
    cursor: pointer;
    outline: none;
    text-align: center;
    position: relative;
    text-align: center;
    white-space: nowrap;
    padding: 0 3rem;
    @media screen and (max-width:768px) {
        min-width: 3rem;
    }
    @media screen and (max-width:565px) {
        min-width: 1.5rem;
        padding: 0 1rem;
        min-height: 1.7rem;
    }

}

// .mobileResult{
//     display: revert;
//     @media screen and (max-width:565px) {
//         display: none;
//     }
// }
