@import '../../styles/colors';

.container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    .subcontainer {
        width: 50rem;
        max-width: 100%;

        .shareCardContainer {
            border: 1px solid $color-gray;
            border-radius: 0.3rem;
            box-shadow: -1px 1px 1px $color-gray;
            box-sizing: border-box;
            margin-bottom: 2rem;

            h3 {
                text-align: center;
                padding: 1rem;

            }

            .instructionsContainer {
               margin-bottom: 2rem;

               .instructions {
                 text-align: left;
                 margin-left: 20%;
                 font-size: 1rem;
               }

               .centered_instructions {
                 text-align: center;
                 margin-left: 20%;
                 font-size: 1rem;
               }
            }

        }

    }
}

.error {
   text-align: center;
   font-size: 1.5rem;
   margin-top: 3rem;
   margin-bottom: 3rem;
}

.link {
    color: $color-blue;
    font-weight: bold;
    white-space: nowrap;
    cursor: pointer;
    border: none;
    background-color: transparent;
    text-decoration: underline;
    font-size: 1rem;

    svg {
        margin: 0 0.5rem;
    }

    &:global(.red) {
        color: $color-btn-red;
    }
}
