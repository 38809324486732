@import '../../styles/colors';

.loader {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba($color-bg-header, 0.8);
    position: absolute;

    svg {
        animation:spin 2s linear infinite;
        font-size: 2rem;
        color: $color-btn-green;
    }
}

@keyframes spin { 100% { transform: rotate(360deg); } }