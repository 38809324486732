@import '../../../styles/colors';

.listContainer {
    display: flex;
    justify-content: space-around;

    .listSubContainer {
        width: 768px;
    }
}

.buttonContainer {
    display: flex;
    justify-content: space-around;
    margin-top: 2rem;

    svg {
        margin-left: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .listContainer {
        .listSubContainer {
            width: 100%;
        }
    }
}

.character {
    position: relative;
    box-sizing: border-box;
    border: 2px solid transparent;
    border-radius: 7px;
    padding: 7px;

    .subcontainer {
        width: 105px;
        height: 105px;

        img {
            display: block;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            clip-path: circle(50% at 50% 50%);
            border: 3px solid $color-btn-green;
            border-radius: 50%;
        }
    }

    &.selected {
        border-color: $color-btn-green;
        background-color: rgba($color-btn-green, 0.3);
    }

    &:hover {
        border-color: rgba($color-border, 0.3);
    }
}