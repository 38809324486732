@import '../../styles/colors';

.progress {
    width: 100%;
    height: 0.6rem;
    display: flex;
    flex-direction: row;
    background-color: rgba($color-btn-green, 0.4);
    border-radius: 0.3rem;

    .progressElement {
        height: 100%;

        .value {
            background-color: $color-btn-green;
            width: 0;
            height: 100%;
            transition: 0.5s width;
        }

        &:first-child {
            .value {
                &:first-child {
                    border-top-left-radius: 0.3rem;
                    border-bottom-left-radius: 0.3rem;
                }
            }
        }

        &:last-child {
            .value {
                &:last-child {
                    border-top-right-radius: 0.3rem;
                    border-bottom-right-radius: 0.3rem;
                }
            }
        }
    }
}