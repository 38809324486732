@import '../../../styles/colors';


.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 3rem;

    .content {
        width: 768px;
        max-width: 100%;
        min-height: 7rem;
    }
}