.container {
    display: flex;
    justify-content: center;

    .successMessageContainer {
        padding-top: 3rem;
        max-width: 100%;
        align-items: center;

        .successMessage {
          margin-top: 2rem;
          text-align: center;
          font-size: 2rem;
        }

        .link {
          align-content: center;
        }
    }
}
