@import '../../styles/colors';

.container {
    width: 4rem;
    height: 4rem;
}

.circularProgress {
    display: block;
    // margin: 10px auto;
    // max-width: 80%;
    // max-height: 250px;
    display: flex;
    justify-content: center;
    align-content: center;

    .circleBg {
        fill: $color-green-50;
    }

    .circle {
        fill: none;
        stroke-width: 5;
        stroke-linecap: round;
        animation: progress 1s ease-out forwards;
    }

    .percentage {
        text-align: center;
        font-size: 0.50rem;
        font-weight: bold;
    }

    @keyframes progress {
        0% {
          stroke-dasharray: 0 100;
        }
    }


    &.green {
        .circle {
            stroke: $color-green;
        }
    }
}