@import '../../../../styles/colors';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: 680px){
        background-color: $color-gray;
    }
    min-height: 100vh;

    .content {
        margin-top: 6rem;
        margin-bottom: 4rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        min-height: 50vh;
        border-radius: 8px;
        @media screen and (min-width: 680px){
            background-color: $color-bg;
            width: 680px;
        }


        img {
            margin-left: auto;
            margin-right: auto;
            padding-top: 10px;
            padding-bottom: 10px;
            display: block;
            max-width: 90%;
        }

        .text {
            padding: 40px;
        }

    }

    .buttons {
        position: fixed;
        width: 100vw;
        bottom: 0;
        height: 4rem;
        border-top: 1px solid $color-gray;
        background-color: $color-bg-header;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

    }
}
