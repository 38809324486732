@import './colors';

form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    .form-item {
        margin-bottom: 1rem;
        width: 100%;
    
        .form-label {
            font-size: 0.8rem;

            &.required:after {
                content: '*';
                color: $color-error;
            }
        }
        
        input[type=text],
        input[type=email],
        input[type=password],
        input[type=number],
        textarea,
        select,
        .selectField {
            line-height: 1.5rem;
            font-size: 1.2rem;
            border-radius: 4px;
            border: 1px solid $color-border;
            width: 100%;
            padding: 0 0.5rem;
            box-sizing: border-box;

            &[disabled] {
                opacity: 0.4;
                color: $color-txt;
            }
        }
    
        .errors {
            min-height: 1.5rem;
            display: block;
            font-size: 0.8rem;
            line-height: 1rem;
        }
    }


    .form-buttons {
        margin-bottom: 2rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &.single {
            justify-content: center;
        }
    }
}

