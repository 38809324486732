@import '../../../styles/colors';

.formContainer {
    max-width: 750px;
    margin-left: 10%;
    margin-top: 3rem;
}

.info {
  border: 1px solid $color-orange;
  border-radius: 0.3rem;
  box-shadow: -1px 1px 1px $color-orange-dark;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin-bottom: 2rem;

  .message {
    padding: 1rem 1rem;
  }
}

.version {
  text-align: center;
  font-size: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.basicInfo {
  justify-content: center;
  align-items: center;
  border: 1px solid $color-gray;
  border-radius: 0.3rem;
  box-shadow: -1px 1px 1px $color-gray;
  box-sizing: border-box;
  margin-bottom: 2rem;
  margin-top: 2rem;
  margin-left: 25%;
  width: 50%;

  .hint {
      display: flex;
      align-items: center;
      justify-content: center;        
  }

  .basicItem {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      padding: 1rem 4rem;
      box-sizing: border-box;
      background-color: $color-gray-light;

      &:nth-child(2n) {
          background-color: $color-bg;
      }

      .basicItemName {
          flex-grow: 10;
          white-space: nowrap;
          font-weight: normal;
      }

      .basicItemValuePlain {
          align-items: right;
          font-weight: normal;
      }

      .basicItemValue {
          margin-left: 2rem;
      }
    }
  }

  .container {
      display: flex;
      justify-content: center;

      .subcontainer {
          width: 30rem;
          max-width: 100%;

          .centered {
              display: flex;
              align-items: center;
              justify-content: center;
          }
      }
  }

  .groups {
      .groupContainer {

          &:first-of-type {
              border-top-left-radius: 0.3rem;
              border-top-right-radius: 0.3rem;
          }

          &:last-of-type {
              border-bottom-left-radius: 0.3rem;
              border-bottom-right-radius: 0.3rem;
          }

          .groupInfo {
              padding: 1rem;
              cursor: pointer;
              display: flex;
              flex-direction: row;
              font-weight: bold;
              justify-content: space-between;

              svg {
                  font-size: 1.5rem;
                  color: $color-gray-dark;
              }

              &:hover {
                  background-color: $color-orange-lightest;
              }

              .link {
                  margin-left: 1rem;
                  font-weight: normal;
                  text-decoration: underline;
              }

              .groupName {
                display: flex;
                flex-direction: row;
              }
          }
        }
      }
