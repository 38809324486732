@import '../../styles/colors';

.main {
    background-color: $color-bg;
    min-height: 100vh;
}

.header {
    box-sizing: border-box;
    width: 100vw;
    min-height: 4rem;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    padding: .5rem 1rem;
    background-color: $color-bg-header;
    position: fixed;
    z-index: 998;
    border-bottom: 1px solid $color-gray;

    .logoContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        // position: absolute;

        .logo {
            width: 2.5rem;
            margin-right: .5rem;
        }
    }

    h1 {
        margin: 0 auto;
        @media screen and (max-width:565px) {
            display: none;
        }
    }

    .headerContainer {
        width: 768px;
        max-width: 60%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .userContainer {
        position: absolute;
    }
    .searchContainer{
        display: flex;
    }
    .menuContainer {
        display: flex;
        flex-direction: row;
        margin-left: 10px;
        align-items: center;

        @media screen and (max-width:565px) {
            display: block;
            margin-left: 0;
        }

        .search {
            display: flex;
            flex-direction: row;
            align-items: center;
            position: absolute;
            left: 150px;
            @media screen and (max-width:565px) {
                left: 20px;
                top: 15px;
            }
        }

        .menuBtn {
            font-size: 1.5rem;
            cursor: pointer;
            margin: 0.3rem 1rem;
            @media screen and (max-width:565px) {
               font-size: 15px;
               margin: 0.3rem 0.5rem;
               margin-right: 0;
            }
        }


        ul {
            display: flex;
            flex-direction: row;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                margin: 0;
                padding: 0 0.5rem;
                border-left: 1px solid $color-gray-dark;
                margin-top: 0.4rem;
                &:first-child {
                    border: none;
                }
                @media screen and (max-width:565px) {
                    padding: 0 0.2rem;
                }

                a {
                    font-weight: 300;
                    text-decoration: underline;
                    padding: 0.3rem 1rem;

                    &.active {
                        font-weight: bold;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}


.authenticated {
    .header {
        justify-content: space-between;
    }
}

.body {
    padding: 1rem;
    padding-top: 4rem;
    box-sizing: border-box;
    min-height: 100vh;

    &.noPaddings {
        padding: 0;
    }
}

//@media screen and (max-width: 768px) {

//}

@media screen and (max-width: 768px) {
  .body {
      padding: 1rem;
      padding-top: 3rem;
      box-sizing: border-box;
      min-height: 100vh;

  }

    .header {
        flex-wrap: wrap;

        .headerContainer {
            width: 90vw;
            max-width: 90vw;
            order: 3;
            margin-top: 1rem;
        }


        .userContainer {
            width: auto;
        }
    }

}
// .mobile_search{
//   display: revert;
//   @media screen and (max-width:565px) {
//       display: none;
//   }
// }