@import '../../../styles/colors';

.headerText {

    div {
        margin-top: 1rem;
        font-size: 1rem;
        font-weight: 400;
    }

    button {
        text-decoration: underline;
    }
}

.listContainer {
    display: flex;
    justify-content: space-around;
    overflow-y: auto;
    padding-bottom: 5rem;

    .listSubContainer {
        width: 768px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.buttonContainer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-around;
    background-color: $color-bg-header;

    svg {
        margin-left: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .listContainer {
        .listSubContainer {
            width: 100%;
        }
    }
}

.narrative {
    position: relative;
    box-sizing: border-box;
    border: 2px solid transparent;
    margin: 1rem 0.7rem;
    width: 120px;
    cursor: pointer;

    .selectionContainer {
        padding: 7px 0;
        border-radius: 5px;
        border: transparent 2px solid;
        display: flex;
        justify-content: center;

        .imageContainer {
            width: 100px;
            height: 153px;
            background-color: $color-gray;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

    }

    .title {
        line-height: 1rem;
        margin-top: 0.5rem;
        text-align: center;
        font-size: 0.9rem;
    }

    &.selected {
        .selectionContainer {
            border-color: $color-btn-green;

            .imageContainer {
                background-color: $color-btn-green;

                img {
                    opacity: 0.5;
                }
            }
        }
    }

    &:hover:not(.locked) {
        .selectionContainer {
            border-color: rgba($color-border, 0.3);
        }
    }

    .locker {
        position: absolute;
        width: 1.5rem;
        height: 1.5rem;
        font-size: 0.7rem;
        color: $color-txt;
        background-color: $color-gray;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        filter: drop-shadow( 0 0 3px $color-txt);
        right: 0;
        transform: translateY(-40%) translateX(30%);
    }
}