@import '../../../../styles/colors';

.version {
  text-align: center;
  font-size: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.basicInfo {
  border: 1px solid $color-gray;
  border-radius: 0.3rem;
  box-shadow: -1px 1px 1px $color-gray;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin-bottom: 2rem;

  .basicItem {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      padding: 1rem 1rem;
      width: 100%;
      box-sizing: border-box;
      background-color: $color-gray-light;

      &:nth-child(2n) {
          background-color: $color-bg;
      }

      .basicItemName {
          flex-grow: 10;
          white-space: nowrap;
          font-weight: normal;
      }

      .basicItemVal {
          width: 7rem;
      }
    }
  }

  .container {
      display: flex;
      justify-content: center;

      .subcontainer {
          width: 30rem;
          max-width: 100%;
          margin-top: 3rem;

          .centered {
              display: flex;
              align-items: center;
              justify-content: center;
          }
      }
  }
