@tailwind base;

@tailwind components;

@tailwind utilities;


.custom {
  font-family: inherit; 
  font-feature-settings: inherit; 
  font-variation-settings: inherit; 
  font-size: 100%; 
  font-weight: inherit; 
  color: inherit; 
  margin: 0; 
  box-sizing: border-box;
  border-width: 0;
  border-style: solid; 
  border-color: theme('borderColor.DEFAULT', currentColor);
}

.custom-btn {
  -webkit-appearance: button; /* 1 */
  background-color: transparent; /* 2 */
  background-image: none; /* 2 */
}

