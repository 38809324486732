@import "../../../../styles/colors";


.viewer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .dataContainer {
        width: 80%;
        padding: 2rem;

        .basicInfo {
            justify-content: center;
            align-items: center;
            border: 1px solid $color-gray;
            border-radius: 0.3rem;
            box-shadow: -1px 1px 1px $color-gray;
            box-sizing: border-box;
            margin-bottom: 2rem;
            margin-top: 2rem;
            

            .basicItem {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                font-weight: bold;
                padding: 1rem 3rem;
                box-sizing: border-box;
                background-color: $color-gray-light;

                &:nth-child(2n) {
                    background-color: $color-bg;
                }

                .basicItemName {
                    flex-grow: 10;
                    white-space: nowrap;
                    font-weight: normal;
                }

                .basicItemValuePlain {
                    align-items: right;
                    font-weight: normal;
                }

                .basicItemValue {
                    margin-left: 2rem;
                }

                .itemActions{
                    margin-left: 1rem;
                }
            }
        }        
    }    
}

.list {

    td {
        &:first-child {
            position: relative;

            &.character {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: auto;
            }

            img.characterPhoto {
                display: block;
                width: 4rem;
                height: 4rem;
                margin-right: 2rem;
                box-sizing: border-box;
                clip-path: circle(50% at 50% 50%);
                border: 3px solid $color-btn-green;
                border-radius: 50%;
            }

            .markContainer {
                height: 100%;
                display: flex;
                flex-direction: row;

                .mark {
                    position: absolute;
                    top: 10%;
                    left: 0;
                    height: 80%;
                    width: 1px;
                    float: left;
                    border-left: 5px solid $color-green;
                    margin-right: 2rem;

                    &.disabled {

                        border-color: $color-orange;
                    }
                }

                .title {
                    margin-left: 1rem;
                    flex-grow: 20;
                }
            }

        }
    }

}

@media screen and (max-width: 768px) {
    .viewer {

        .dataContainer {
            box-sizing: border-box;
            width: 100%;
            padding: 1rem;
        }
    }
}
