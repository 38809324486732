@import '../../../../styles/colors';

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.hidden {
    display: none;
    opacity: 0;
}

.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 5rem;
    min-height: 100vh;
    padding-bottom: 6rem;

    .introContainer {
        max-width: 400px;

        .introTitle {
            text-transform: uppercase;
            font-size: 1.2rem;
            font-weight: 600;
            text-align: center;
            margin: 2rem;
        }

        .introNarratives {
            display: flex;
            flex-direction: row;
            justify-content: center;

            img {
                margin: 2rem;
                height: 10rem;
            }
        }

        .centeredBlock {
            justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: column;

            .spaced {
                padding-bottom: 2rem;
            }
        }

        .introHintText {
            color: $color-gray-text;
        }

        .narrativeLinkText {
            color: $color-gray-text;
            text-align: center;
        }

    }

    .videoContainer {
        width: 100vw;
        height: 60vw;
        max-width: 960px;
        max-height: 540px;

        .video {
            width: 100%;
            height: 100%;
        }
    }

    .questionContainer {
        width: 100vw;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        .questionVideo {
            width: 480px;
            max-width: 100%;
            max-height: 270px;
            margin-left: -270px;
            margin-right: 3vw;
            margin-bottom: 2rem;
            display: flex;
            flex-direction: column;
            align-items: center;

            .sceneImage {
                width: 100%;
            }
        }

        .questionInfoContainer {
            width: 650px;
            max-width: 100%;
            min-height: 50vh;

            .characters {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                flex-wrap: wrap;

                .character {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 0.5rem;
                    font-size: 0.8rem;
                    margin-right: 3rem;

                    img {
                        width: 3rem;
                        height: 3rem;
                        border-radius: 50%;
                        border: 2px solid $color-btn-green;
                        margin-right: 1rem;
                    }
                }
            }

            .exerciseHeadingText {
                text-transform: uppercase;
                font-size: 1.2rem;
                font-weight: 600;
                margin-bottom: 2rem;
            }

            .exerciseInitialPrompt {
                margin-bottom: 2rem;

                &:not(.hidden) {
                    animation-duration: 2s;
                    animation-name: fadein;
                }
            }


            .question {

                &:not(.hidden) {
                    animation-duration: 2s;
                    animation-name: fadein;
                }

                .questionText {
                    font-weight: bold;
                }

                .questionHintText {
                    margin-top: 1rem;
                }

                .videoHintText {
                    margin-top: 2rem;
                    margin-bottom: 2rem;
                }

                .userChoiceReviewPromptText {
                  margin-top: 1rem;
                  color: $color-btn-green;
                }

                .answers {
                    padding-top: 2rem;
                    padding-bottom: 4rem;

                    .requirements {
                        color: $color-gray-dark;
                        margin-bottom: 2rem;
                    }

                    h2 {
                        text-align: center;
                    }

                    .selected {
                        stroke: $color-orange;
                        stroke-width: 3;
                        fill-opacity: 0.8;
                    }

                    .answer {
                        position: relative;
                        margin-top: 2rem;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        cursor: pointer;
                        min-height: 2rem;

                        .answers_value {
                            position: absolute;
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            svg {
                                font-size: 2rem;
                                margin-right: 1rem;
                                color: $color-border;
                            }
                        }

                        .answers_count {
                            position: absolute;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            height: 100%;
                            width: 100%;

                            .answers_count_line {
                                width: 0%;
                                background-color: $color-gray;
                                height: 100%;
                                border-radius: 4px;
                                margin-left: 3rem;
                            }
                        }

                        &.selected {
                            color: $color-btn-green;

                            .answers_count_line {
                                background-color: $color-green-25;
                            }

                            .answers_value {
                                svg {
                                    color: $color-btn-green;
                                }
                            }
                        }

                        &.selectedInvalid {
                            color: $color-error;

                            .answers_value {
                                svg {
                                    color: $color-error;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .buttons {
        position: fixed;
        width: 100vw;
        bottom: 0;
        height: 4rem;
        border-top: 1px solid $color-gray;
        background-color: $color-bg-header;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

}

.formButtons {
    margin-bottom: 2rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.formSelectors {
    margin-bottom: 2rem;    
}

.exerciseEditor {
    width: 35rem;

    .exIndex {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .form-buttons {
        margin-bottom: 2rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

    }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1rem;
    }

    .error {
        margin: 2rem 0;
        color: $color-error;
    }

    .questions {

        .nothing {
            color: $color-gray-dark;
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;
        }

        .question {
            .text {
                margin-top: 1rem;
            }

            .disclaimer {
                font-size: .75rem;
                font-style: italic;
            }

            .answers {
                .answersHeader {
                    margin-top: 1rem;
                    border-bottom: 1px solid $color-border;
                }

                .answer {
                    padding: 1rem;

                    .correct {
                        background-color: $color-green;
                        color: $color-white;
                        padding: 0 0.5rem;
                        margin-left: 2rem;
                    }

                    .answerFeedback {
                        color: $color-gray-dark;
                    }

                    &:nth-child(2n) {
                        background-color: $color-gray-light;
                    }
                }

            }
        }
    }
}


@media screen and (max-width: 1400px) {
    .container {
        .questionContainer {

            .questionVideo {
                margin-left: 0;
            }

            .question {
                margin: 1rem;
                margin-top: 0;
            }
        }
    }
}
