.groupButtons {
    display: flex;
    flex-direction: row;
    height: 3rem;

    :global(.groupButton) {
        border-radius: 0;
        margin-right: 0.5rem;
        
        &:first-child {
            border-top-left-radius: 2rem;
            border-bottom-left-radius: 2rem;
        }
        
        &:last-child {
            border-top-right-radius: 2rem;
            border-bottom-right-radius: 2rem;
            margin-right: 0;
        }
    }
}