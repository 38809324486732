@import './colors';

.list-title {
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid $color-orange;
    padding-bottom: 1rem;
}

table.list {
    margin-top: 1rem;
    width: 100%;
    border: 1px solid $color-gray;

    th {
        font-weight: 500;
        color: $color-bg;
        background-color: $color-gray-text;
        padding: 0.5rem;
        border-left: 1px solid $color-bg;

        &:first-child {
            border-left: none;
        }
    }

    td {
        padding: 0.5rem;
        border-bottom: 1px solid $color-gray;
        border-left: 1px solid $color-bg;

        &:first-child {
            border-left: none;
        }

        &.center {
            text-align: center;
        }

        &.right {
            text-align: right;
        }

        .list-item-comment {
            color: $color-gray-text;
        }
    }

    th:last-child,
    td:last-child {
        width: 20%;
        border-left: 1px solid $color-bg;
    }

    tr:nth-child(even) {
        td {
            background-color: lighten($color-gray, 10%);
        }
    }

    tr:hover {
        td {
            background-color: $color-orange-lightest;
        }
    }

}

