@import './styles/colors';
@import './styles/forms';
@import './styles/ckeditor';
@import './styles/list';

//@import '../node_modules/react-vis/dist/style.css';

html, body, #root, .page {
    width: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 1.5rem;
    color: $color-txt;
}

.page {
    position: relative;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
    font-size: 2rem;
    line-height: 1em;
    text-align: center;

    span {
        display: inline-block;
        font-size: 1rem;
        line-height: 1.2rem;
        color: $color-txt-comment;
        font-weight: 300;
    }
}

h2 {
    color: $color-orange;
}

.errors, .red {
    color: $color-error !important;
}

.warn, .orange {
    color: $color-orange-dark,
}

.success, .green {
    color: $color-btn-green;
}
