@import '../../../../styles/colors';

.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 3rem;

    .menu {
        width: 15rem;
        max-width: 100%;
        margin-bottom: 3rem;
        margin-right: 5vw;
        margin-left: -5vw;

        .menuSubcontainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 1px solid $color-gray;
            border-radius: 0.3rem;
            padding: 1rem;
            box-shadow: -1px 1px 1px $color-gray;
        }

        a, button {
            padding: 0.5rem;
            width: calc(100% - 2rem);
            text-align: center;

            &:hover {
                background-color: $color-orange-lightest;
            }

            &.active {
                background-color: $color-orange-light;
            }
        }

        .separator {
            border-top: 1px solid $color-gray;
            width: 100%;
            margin: 1rem 0;
        }

        .extensionBanner {
            background-color: $color-orange-light;
            margin: 2rem 0;
            padding: 1rem;
            border-radius: 0.3rem;
        }
    }

    .content {
        width: 768px;
        max-width: 100%;
        min-height: 7rem;
    }

}



@media screen and (max-width: 1200px) {
    .container {
        .menu {
            width: 100%;
            max-width: 768px;
            margin-right: 0;
            margin-left: 0;
        }
    }
}
