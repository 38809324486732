@import '../../../styles/colors';

.link {
    text-decoration: underline;
}

.container {
    padding: 1rem;

    .narrative {
        .narrativeTitle {
            font-weight: bold;
        }
    }

    .characters {
        h3 {
            font-weight: 500;
        }

        .character {
            display: flex;
            flex-direction: row;
            margin-top: 1rem;

            img {
                height: 5rem;
                width: 5rem;
                border-radius: 2.5rem;
            }

            .characterInfo {
                padding-left: 1rem;
                width: 20rem;
                max-width: 100%;
                display: flex;
                align-items: center;

                .characterDescription {
                    color: $color-gray-text;
                    font-size: 0.8rem;
                    line-height: 1rem;
                    margin-left: 0.5rem;
                    margin-top: 0.3rem;
                }
            }
        }
    }

}
