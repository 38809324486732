@import '../../styles/colors';

.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .pager {
        margin: 0.5rem 1rem 0.5rem 0;
        
        button {
            border: 1px solid $color-bg;
            min-width: 1.7rem;
            line-height: 1.2rem;
            border-radius: 4px;
            padding: 0;
            margin: 0;
            margin-right: 5px;
            font-size: 0.8rem;

            &.prevNext {
                color: $color-txt;
            }

            &[disabled] {
                color: $color-gray-dark;
                opacity: 0.5;
            }
        }

        span {
            &.less, &.greater {
                margin-right: 5px;
            }
        }

        .selected {
            background-color: $color-green;
        }
    }

    .text {
        margin: 0.5rem 0 0.5rem 1rem;
        font-size: 0.8rem;
    }
}