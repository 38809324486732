@import '../../../styles/colors';


.content {
    min-height: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .input {
        font-size: 2rem;
        border-radius: 4px;
        border: 1px solid $color-border;
        width: 100%;
        margin: 2rem 0;
    }

    button {
        margin-top: 2rem;
    }
}

