.container {
    display: flex;
    justify-content: center;

    .subcontainer {
        width: 30rem;
        max-width: 100%;

        .termsInfoContainer {
            padding: 2rem 0;
        }

        .termsButton {
            text-decoration: underline;
        }
    }
}

.termsTextContainer {
    width: 40rem;
    max-width: 100%;
}
