@import '../../styles/colors';

.selectField {
    position: relative;
    background-color: $color-bg-header;
    min-height: 1.5em;
    min-height: 1.5em;
    border: 1px solid #a4a4a4;
    border-radius: 5px;
    padding: 0.25rem;

    &.singleSelect {
        height: 1.5em;
    }

    &.disabled {
        color: $color-gray-dark;
        background-color: $color-bg;
        border-color: $color-gray;

        svg {
            color: $color-gray;
        }
    }

    .fieldSubContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .valueContainer {
            width: calc(100% - 2em);

            &.singleSelect {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .value {
                border: 1px solid $color-gray-dark;
                float: left;
                padding: 0 0.2rem;
                margin: 0.2rem;
                border-radius: 5px;
                background-color: $color-gray-light;

                svg {
                    color: $color-gray-text;
                    margin-left: 0.5rem;
                    cursor: pointer;
                }
            }
        }

        .empty {
            color: $color-gray-text;
        }

    }

    .clearButton {
        position: absolute;
        right: 3rem;
        width: 2rem;
        height: 1.6rem;
        margin-top: -1.5rem;
        text-align: center;
    }

    .locker {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .options {
        position: absolute;
        box-sizing: border-box;
        min-height: 1.5em;
        border: 1px solid $color-border;
        border-radius: 4px;
        width: 100%;
        margin-top: 3px;
        background-color: $color-gray-light;
        z-index: 10;
        right: 1px;

        .search {
            display: flex;
            position: relative;
            border-bottom: 1px solid $color-border;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            background-color: $color-gray;
            padding: 0.5rem;

            input {
                padding: 2px 2rem;
            }

            .searchIcon {
                position: absolute;
                color: $color-green;
                margin-top: 0.2rem;
                margin-left: 0.2rem;
            }

            .searchClear {
                position: absolute;
                right: 1rem;
                margin-top: 0.2rem;
                color: $color-gray-text;
                cursor: pointer;
            }
        }

        .addButtonContainer {
            display: flex;
            justify-content: center;
            padding: 0.5rem;
            border-bottom: 1px solid $color-bg;
            background-color: $color-gray;
        }

        .optionsContainer {
            padding: 2px 5px;

            .option {
                padding: 0.2em 1em;
                width: 100%;
                box-sizing: border-box;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                border-bottom: 1px solid $color-bg;

                &:hover {
                    background-color: $color-gray-light;
                }

                &.active.selected {
                    background-color: $color-green-light;
                }

                &.active:hover {
                    background-color: $color-orange-light;
                }

                span {
                    font-weight: bold;
                }
            }

            .childrenContainer {
                margin-left: 1em;
                margin-bottom: 0.5em;
            }
        }
    }

}