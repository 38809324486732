.container {
    display: flex;
    justify-content: center;

    .container {
        width: 20rem;
        max-width: 100%;
    }

    .subcontainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}