@import '../../../../styles/colors';


.status {
    display: flex;
    flex-direction: row;
    background-color: $color-orange-lightest;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    max-width: 600px;

    >div {
        margin-left: 3rem;

        &:first-child {
            margin-left: 0;
        }
    }

    .icon {
        color: $color-orange-dark;
        margin-right: 0.5rem;
    }
}
