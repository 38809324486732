@import '../../styles/colors';

.container {
    position: relative;
    width: 100%;

    .buttonLeft,
    .buttonRight {
        font-size: 1.5rem;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 1.5rem;
        border: 1px solid $color-gray;
        position: absolute;
        top: 50%;
        transform: translateY(-60%);
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-txt-comment;
        background-color: $color-gray;
        cursor: pointer;

        &:hover {
            background-color: $color-bg;
        }
    }

    .buttonLeft {
        left: 0;
    }

    .buttonRight {
        right: 0;
    }

    .scrollContainer {
        margin: 0 2.5rem;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;

        .subcontainer {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-start;
            height: 380px;

            .item {
                box-sizing: border-box;
                scroll-snap-align: end;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px;
            }
        }
    }
}